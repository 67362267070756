<template>
  <Header />
  <div class="about">
    <div class="container">
      <div class="row">
        <div class="col-3 text-center">
          <!-- <img src="/carlos.png" alt="Carlos Pastor" class="d-block  mx-auto">
          <h3 class="h4 text-center mt-2">Carlos Pastor</h3>
          <h2 class="h2 golden-text text-center">Founder</h2> -->
        </div>
        <div class="col-9">
          <h1 class="h1">About Us</h1>
          <p>
            Hello! I'm Carlos Pastor, founder of CM & AP. LLC.  Power by Realtor Tools. A few years ago,
            I became a realtor, and it was then that the idea emerged to combine
            my previous experience as an economist with my 18-year career as an
            analyst, developer, evaluator, and financial manager in investment
            projects. That's how Realtor Tools was born.
          </p>
          <p>
            Realtor Tools is more than just a system; it's a powerful tool
            designed to transform the way real estate professionals operate. Our
            objective is clear:
          </p>
          <ul>
            <li>
              <strong>Generate effective leads:</strong> Realtor Tools enables
              real estate agents to maximize their reach by efficiently
              connecting with Mortgage Loan Originators, title companies, real
              estate attorneys, and home inspectors.
            </li>
            <li>
              <strong>Global expansion:</strong> The system is designed for
              realtors to generate leads not only at a local level but also at
              the state, national, and international levels.
            </li>
            <li>
              <strong>Expert financial analysis:</strong> Realtor Tools offers
              advanced calculation tools that allow realtors to estimate
              financial outcomes for both residential and investment clients,
              positioning them as true experts in their field.
            </li>
          </ul>
          <p>The system includes 20 specialized applications covering:</p>
          <ul>
            <li>
              <strong>Residential sector:</strong> Estimating purchasing power,
              financing, and total monthly payments for buyers.
            </li>
            <li>
              <strong>Investment sector: </strong>Evaluating return on
              investment before making decisions, minimizing risk for both
              domestic and foreign clients.
            </li>
          </ul>
          <p>
            In today's market, professional success depends on the combination
            of technology and skill. Realtor Tools is the tool you need to
            globalize your business and elevate your expertise to the next
            level.
          </p>
          <h5 class="h5">Carlos Pastor</h5>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>


<style lang="scss">
.about {
  padding: 4rem 0;
  h1, h2, h3, h4, h5, h6, p, ul, li {
    text-align: left;
  }
  p {
    margin-bottom: 1rem;
  }
  ul {
    margin-bottom: 1rem;
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    list-style: disc;
  }
}
</style>
