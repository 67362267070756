<template>
  <div class="position-relative">
    <Loader :visible="loading" />
    <h2 class="tw-text-center tw-text-lg tw-text-[20px] tw-border-b-[2px] tw-border-[#b0b0b0] tw-pb-2">
      {{ title }}
    </h2>
    <div v-if="!hiddeMLO" class="tw-border-[2px] tw-border-[#d2d2d2] tw-mt-5 tw-rounded-xl tw-overflow-hidden">
      <h2 class="tw-bg-[#00a0da] tw-text-white tw-px-4 tw-pt-2.5 tw-pb-2 tw-text-xl tw-font-semibold tw-text-left">
        MORTGAGE LOAN ORIGINATOR
      </h2>
      <div class="tw-px-4">
        <div class="tw-flex tw-justify-between tw-items-center tw-mt-3 tw-gap-5 tw-text-left">

          <div class="tw-w-full">
            <label for="">YEARS</label>
            <select name="" id=""
              class="tw-w-full tw-border-[#b6b6b6] tw-outline-none tw-h-[45px] tw-border tw-rounded-lg tw-overflow-hidden tw-px-1 tw-cursor-pointer tw-mt-2"
              v-model="filters.year">
              <option :value="year.value" v-for="(year, index) in selectYears" :key="year.id">
                {{ year.label }}
              </option>
            </select>
          </div>
        </div>


        <div class="tw-flex tw-items-center tw-justify-center tw-mt-5 tw-gap-5 tw-text-left tw-mb-5">
          <button
            class="tw-bg-[#00a0da] tw-px-11 tw-text-white tw-text-base tw-font-semibold tw-rounded-full tw-py-1 tw-w-[200px]"
            @click="getMLO()">
            RUN
          </button>
        </div>

        <div v-if="MLOtoShow" class="tw-mt-4 tw-mb-12">
          <table class="tw-w-full table-edit">
            <thead>
              <tr>
                <th class="tw-bg-[#00a0da] tw-rounded-tl-2xl tw-rounded-tr-2xl" :colspan="MLOtoShow.length + 1">
                  <div class="tw-flex tw-justify-center tw-text-white tw-text-base tw-py-2">
                    <p class="tw-text-[13px]">PERSONAL DATA</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="tw-even:bg-gray-50 tw-odd:bg-white">
                <td class="tw-text-center tw-font-normal tw-w-1/4 tw-text-base tw-py-1 tw-px-3">
                  NAME
                </td>
                <td class="tw-text-left tw-font-normal tw-w-1/4 tw-text-base tw-py-1 tw-px-3"
                  v-for="(mlo, index) in MLOtoShow" :key="index">
                  <span>{{
                    mlo.realtorMLOData.name + " " + mlo.realtorMLOData.lastname
                    }}</span>
                </td>
              </tr>
              <tr class="tw-even:bg-[#eaeaea] tw-odd:bg-white">
                <td class="tw-text-center tw-font-normal tw-w-1/4 tw-text-base tw-py-1 tw-px-3">
                  PHONE NUMBER
                </td>
                <td class="tw-text-left tw-font-normal tw-w-1/4 tw-text-base tw-py-1 tw-px-3"
                  v-for="(mlo, index) in MLOtoShow" :key="index">
                  <span>{{
                    mlo.realtorMLOData.phone
                    }}</span>
                </td>
              </tr>
              <tr class="tw-even:bg-[#eaeaea] tw-odd:bg-white">
                <td class="tw-text-center tw-font-normal tw-w-1/4 tw-text-base tw-py-1 tw-px-3">
                  EMAIL
                </td>
                <td class="tw-text-left tw-font-normal tw-w-1/4 tw-text-base tw-py-1 tw-px-3"
                  v-for="(mlo, index) in MLOtoShow" :key="index">
                  <span>{{ mlo.realtorMLOData.email }}</span>
                </td>
              </tr>
              <tr class="tw-even:bg-[#eaeaea] tw-odd:bg-white">
                <td class="tw-text-center tw-font-normal tw-w-1/4 tw-text-base tw-py-1 tw-px-3">
                  INTERES RATE
                </td>
                <td class="tw-text-left tw-font-normal tw-w-1/4 tw-text-base tw-py-1 tw-px-3"
                v-for="(mlo, index) in MLOtoShow" :key="index">
                  {{ mlo.interest_rate }}
                </td>
              </tr>
              <tr class="tw-even:bg-[#eaeaea] tw-odd:bg-white">
                <td class="tw-text-center tw-font-normal tw-w-1/4 tw-text-base tw-py-1 tw-px-3">
                  MINIMUM DOWN PAYMENT
                </td>
                <td class="tw-text-left tw-font-normal tw-w-1/4 tw-text-base tw-py-1 tw-px-3"
                 v-for="(mlo, index) in MLOtoShow" :key="index">
                  {{
                    mlo.down_payment
                      ?mlo.down_payment + "%"
                      : "-"
                  }}
                </td>
              </tr>
              
            </tbody>
          </table>
          <table class="tw-w-full table-edit tw-shadow-none tw-border-none tw-mt-2">
            <tr class="tw-[&>td]:border-r-0">
              <td class="tw-border-none"></td>
              <td class="tw-border-none tw-px-4" v-for="(mlo, index) in MLOtoShow" :key="index">
                <button @click="setMLO(mlo)"
                  class="tw-bg-[#00a0da] tw-px-11 tw-text-white tw-text-base tw-font-semibold tw-rounded-full tw-py-1 tw-mt-5">
                  GET IT
                </button>
              </td>
            </tr>
            
          </table>
        </div>
        <div v-else class="tw-mt-4 tw-mb-12">
          <p>NO MLO'S TABLES FOUND</p>
        </div>
      </div>
    </div>
    <Customer :customer="customer" />
  </div>
</template>

<script setup>
import axios from "axios";
import { ref, toRefs } from 'vue';
const emit = defineEmits(['update:usingMLO'])
import Customer from "./Customer.vue";
import { useToast } from "@/composables/useToast";
const toast = useToast();
import { environment } from "@/environment";
import ForeignTables from "../../Tables/ForeignTables.vue";


//VARIABLES
const url = environment.URL_API;
const loading = ref(false);

const props = defineProps({
  userSession: Object,
  title: String,
  hiddeMLO: {
    type: Boolean,
    default: false
  },
  usingMLO: {
    type: Object,
    default: null
  },
  
  customer: {
    type: Object,
    default: {
      name: '',
      phone: '',
      email: ''
    }
  }
});
const { userSession, title, usingMLO, customer } = toRefs(props);

const selectYears = [
  {
    value: null,
    label: 'SELECT YEAR'
  },
  {
    value: 15,
    label: '15 YEARS'
  },
  {
    value: 30,
    label: '30 YEARS'
  }
]

const filters = ref({
  year: null
})



const MLOtoShow = ref(null)

const getMLO = async () => {
  loading.value = true;
  //console.log(foreign.value.selectType);
  const selectType = document.getElementById("typeforeign").value;
  const type = 'residential';

  const response = await axios(
    `${url}mlo/mloforeign?zipcode=${userSession.value.zipcode}&years=${filters.value.year}&type=${type}&state=${userSession.value.state}`,
    {
      method: "GET",
    }
  );

  if (!response.data.error) {
    MLOtoShow.value = response.data.allMLOForeign
      ? response.data.allMLOForeign
      : null
  }
  loading.value = false;
  
}

const setMLO = async (mlo) => {
  emit('update:usingMLO', { ...mlo, filters: filters.value })
  await toast.success(`Using ${mlo.realtorMLOData.name} ${mlo.realtorMLOData.lastname} as MLO`, {
    position: "top-end",
    timer: 3000,
    showCloseButton: true,
  });
}

const sendData = (mlo) => {
  return false
}

const commaFormat = (value) => {
  if (!value) return "";
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
</script>

<style></style>