<template>
  <loading v-model:active="loaderConfig.isLoading" :can-cancel="false" :loader="'dots'"
    :is-full-page="loaderConfig.fullPage" />
  <div class="hidden-mobile body-color ">
    <div class="sign-up">
      <div class="container-fixed">
        <div class="sign-up__content d-flex justify-content-between">
          <div class="w-50 d-flex justify-content-center ">
            <section v-if="$route.query.type == 2" class="t-6 mb-5 section-body">
              <h3 class="suscribe-title">Subscribe to MLO</h3>
              <p class="price-title">32$ monthly</p>
              <img src="../../assets/Images/logo-login.png" class=" mb-5 mt-3" style="width: 30%;" />
              <table class="billing-details w-100">
                <tr>
                  <td>Subtotal</td>
                  <td></td>
                  <td></td>
                  <td class="td-style bold ">$32.00</td>
                </tr>
                <tr>
                  <td class=" td-style">Tax</td>
                  <td></td>
                  <td></td>
                  <td class="td-style">$2.24</td>
                </tr>
                <tr class="border">
                  <td class="td-style"><b>Total</b></td>
                  <td></td>
                  <td></td>
                  <td class="td-style">$34.24</td>
                </tr>
              </table>

            </section>
            <section v-if="$route.query.type == 1" class="t-6 mb-5 section-body">
              <h3 class="suscribe-title">Subscribe to Realtor</h3>
              <p class="price-title">34$ monthly</p>
              <img src="../../assets/Images/logo-login.png" class=" mb-5 mt-3" style="width: 30%;" />
              <table class="billing-details w-100">
                <tr>
                  <td>Subtotal</td>
                  <td></td>
                  <td></td>
                  <td class="td-style bold ">$34.00</td>
                </tr>
                <tr>
                  <td class=" td-style">Tax</td>
                  <td></td>
                  <td></td>
                  <td class="td-style">$0.00</td>
                </tr>
                <tr class="border">
                  <td class="td-style"><b>Total</b></td>
                  <td></td>
                  <td></td>
                  <td class="td-style">$34.00</td>
                </tr>
              </table>

            </section>
            <section v-if="$route.query.type == 3" class="t-6 mb-5 section-body">
              <h3 class="suscribe-title">Subscribe to Inspector</h3>
              <p class="price-title">22$ monthly</p>
              <img src="../../assets/Images/logo-login.png" class=" mb-5 mt-3" style="width: 30%;" />
              <table class="billing-details w-100">
                <tr>
                  <td>Subtotal</td>
                  <td></td>
                  <td></td>
                  <td class="td-style bold ">$22.00</td>
                </tr>
                <tr>
                  <td class=" td-style">Tax</td>
                  <td></td>
                  <td></td>
                  <td class="td-style">$0.00</td>
                </tr>
                <tr class="border">
                  <td class="td-style"><b>Total</b></td>
                  <td></td>
                  <td></td>
                  <td class="td-style">$22.00</td>
                </tr>
              </table>

            </section>
            <section v-if="$route.query.type == 5" class="t-6 mb-5 section-body">
              <h3 class="suscribe-title">Subscribe to Title Company</h3>
              <p class="price-title">32$ monthly</p>
              <img src="../../assets/Images/logo-login.png" class=" mb-5 mt-3" style="width: 30%;" />
              <table class="billing-details w-100">
                <tr>
                  <td>Subtotal</td>
                  <td></td>
                  <td></td>
                  <td class="td-style bold ">$32.00</td>
                </tr>
                <tr>
                  <td class=" td-style">Tax</td>
                  <td></td>
                  <td></td>
                  <td class="td-style">$0.00</td>
                </tr>
                <tr class="border">
                  <td class="td-style"><b>Total</b></td>
                  <td></td>
                  <td></td>
                  <td class="td-style">$32.00</td>
                </tr>
              </table>

            </section>

          </div>
          <div class=" pr-0 w-50 d-flex justify-content-center align-items-center">
            <form class="custom-card">
              <div>

                <div class="">
                  <div class="">
                    <a class="d-flex align-items-center" href="/">
                      <i class="fas fa-arrow-circle-left"></i>
                      <span> Go back </span>
                    </a>
                  </div>
                </div>
                <!--<img src="../../assets/Images/logo-login.png" class=" mx-auto mb-5" style="width: 30%;"/>-->
                <section v-if="$route.query.type == 1 ||$route.query.type == 2 ||$route.query.type == 3 ||$route.query.type == 5  " class="t-6 mb-5 section-body">
                  <h2 class="justify-start">Contact information</h2>
                </section>

                <section class="t-5 section-body">
                  <Stripe :idUsuario="idUsuario" :roleType="roleType" @payment-success="handlePaymentSuccess" />
                </section>


                <div class="sign-up_contentformulario_form-register d-flex justify-content-center"></div>
                
                <div class="mt-5 box  ">
                  <p>If you have not received the password in your inbox, we recommend checking your spam folder.<br>
                    Thanks.
                  </p>
                </div>
              </div>
            </form>

          </div>

        </div>

      </div>

    </div>

  </div>

</template>



<script>



export default {

  data() {
    return {
      loaderConfig: {
        isLoading: false,
        fullPage: true,
      },
      idUsuario: null,
      roleType: null,
    };
  },
  mounted() {
    this.loaderConfig.isLoading = true;
    this.idUsuario = this.$route.query.userId;
    this.roleType = this.$route.query.type;
    this.loaderConfig.isLoading = false;
  },
  methods: {
    handlePaymentSuccess() {
      this.$router.push('/login');
    },
  },
};
</script>
<style>
  .custom-card{
    background-color: white;
    border-radius: 12px;
    height: 800px;
    width: 600px;
    margin-top: 90px;
    font-size: 20px;
    
  }
 

  .title-payment{
    font-family: "Open Sans", sans-serif;
    font-size:28px;
    color: gray;
    margin-top: 30px;

  }
  .body-color{
    background-color: white;
    
  }
  .suscribe-title{
    margin-top: 90px;
    font-size:28px;
    color: gray;


  }
  .price-title{
    margin-top: 30px;
    font-size:30px;
    color: black;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;

  }
  .section-body {
    text-align: left;
  }
  .p-total{
    margin-top: 20px;
    font-size:22px;
    color: gray;
  }
  .billing-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .text-label{
    display: flex;
  }
  .td-style{
    margin-top: 20px;
    font-size:22px;
    color: gray;
  }
  .box{
    border-radius: 12px;
    font-size: 18px;
    color: gray;
    height: 50px;
    border-color: lightgrey;
  }
</style>